/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import {initialState, EXPORT_ACTIVITY_STATE as STATE} from './exportActivity.types';

export default (state = initialState, action) => {
  switch (action.type) {
    case STATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: null,
        isSuccess: null,
        trigger: action.requestType,
      };
    case STATE.REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.requestType,
      };
    case STATE.FETCH_SUCCCESS:
      if (state.existingDataExport?.length) {
        const existingIds = state.existingDataExport.map(item => item.id);
        const filteredData = action.payload.data.filter(item => !existingIds.includes(item.id));
    
        action.payload.data = [...state.existingDataExport, ...filteredData];
      }
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        exportActivityData: action.payload.data,
        exportActivityMeta: action.payload.meta,
        trigger: action.type,
      };
    case STATE.UPDATE_EXPORT_NOTIF:
      return {
        ...state,
        isLoading: false,
        isSuccess: null,
        trigger: action.type,
      };
    case STATE.UPDATE_EXPORT_NOTIF_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        message: action.payload,
        trigger: action.type,
      };
    case STATE.UPDATE_EXPORT_NOTIF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: true,
        exportNotificationData: [
          ...state.exportNotificationData,
          {
          ...action.payload,
        }],
        trigger: action.type,
      };
      case STATE.UPDATE_EXPORT_NOTIF_STATUS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: true,
          exportNotificationData: state.exportNotificationData.map((item) => {
            if (item.id === parseInt(action.payload.id_export)) {
              return {
                ...item,
                file_path: action.payload.file_path,
                status: action.payload.status,
              };
            }
            return item;
          }),
          trigger: action.type,
        };
     case STATE.EXISTING_EXPORT_ACTIVITY:
      return {
        ...state,
        existingDataExport: action.data
      };
    case STATE.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
