/* eslint-disable no-useless-escape */
/* eslint-disable no-var */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Paper,
  CardMedia,
  Grid,
  Card,
  CardContent,
} from '@material-ui/core';
import {Send, Add, Delete} from '@material-ui/icons';
import styles from './styles';
import {MInput, MButton} from '../../../components/form';
import {toast, isObject, checkFileUploadSize} from '../../../helpers';
import {ORGANIZATION_STATE} from '../../../redux/organization/organization.types';
import {
  getMyOrganizationProfile,
  updateOrganization,
  updateMyProfile,
  checkStatus,
} from '../../../redux/organization/organization.actions';
import _ from 'lodash';
import featureFlag from '../../../config/featureFlag';

const BRANCH_LIMIT = process.env.REACT_APP_BRANCH_LIMIT ? process.env.REACT_APP_BRANCH_LIMIT : 10;

class CompanyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formId: null,
      formName: null,
      formAddress: null,
      formLogo: null,
      formReason: null,
      logoURL: null,
      errorImageSize: null,
      latitude: null,
      longitude: null,
      radius: null,
      groupEmail: [{email: '', error: ''}],
      errorMessage: null,
      location: [],
      refreshState: null,
      pic_name: null,
      pic_number: null,
      pic_email: null,
      npwp_holder: null,
      npwp_id: null,
    };
    this.initialState = this.state;
  }

  UNSAFE_componentWillMount() {
    this.props.checkStatus();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {message, trigger, isSuccess, isError, myCompanyDetail, orgProfile, orgStatusData} = nextProps;

    if (isSuccess && trigger === ORGANIZATION_STATE.UPDATE_SUCCESS) {
      toast('success', message);
      this.props.getMyOrganizationProfile();
      this.props.checkStatus();
    }

    if (isSuccess && trigger === ORGANIZATION_STATE.FETCH_ORG_PROFILE_SUCCESS) {
      if (myCompanyDetail) {
        this.props.updateMyProfile({
          name: this.state.location[0]['name'],
          address: this.state.location[0]['address'],
          latitude: this.state.location[0]['lat'] ? String(this.state.location[0]['lat']) : null,
          longitude: this.state.location[0]['long'] ? String(this.state.location[0]['long']) : null,
          radius: this.state.location[0]['radius'],
          logo: myCompanyDetail.logo,
          location: this.state.location,
          pic_name: this.state.pic_name,
          pic_number: this.state.pic_number,
          pic_email: this.state.pic_email,
        });
      }
    }
    if (isSuccess && trigger === ORGANIZATION_STATE.CHECK_STATUS_SUCCESS) {
      if (orgProfile && orgStatusData.location) {
        this.setState({
          npwp_id: orgProfile.npwp_id,
          npwp_holder: orgProfile.npwp_holder_name,
          formId: orgProfile.id,
          formName: orgProfile.name,
          formAddress: orgProfile.address,
          latitude: orgStatusData.latitude,
          longitude: orgStatusData.longitude,
          radius: orgStatusData.radius,
          logoURL: orgProfile.logo,
          pic_name: orgProfile.pic_name,
          pic_number: orgProfile.pic_number,
          pic_email: orgProfile.pic_email,
          location: orgStatusData.location ? orgStatusData.location : [],
        }, () => {
          if (orgStatusData.location && orgStatusData.location.length !== 0 && (String(orgStatusData.location[0]['lat']) !== orgStatusData.latitude || String(orgStatusData.location[0]['long']) !== orgStatusData.longitude || orgStatusData.location[0]['name'] !== orgProfile.name || orgStatusData.location[0]['address'] !== orgProfile.address)) {
            const {location} = this.state;
            const newLocation = location;
            newLocation[0]['name'] = orgProfile.name;
            newLocation[0]['address'] = orgProfile.address;
            newLocation[0]['lat'] = orgStatusData.latitude ? parseFloat(orgStatusData.latitude) : null;
            newLocation[0]['long'] = orgStatusData.longitude ? parseFloat(orgStatusData.longitude) : null;
            this.setState({
              ...location,
              location: newLocation,
            });
          }
          if ((orgStatusData && !orgStatusData.location) || (orgStatusData && orgStatusData.location.length === 0)) {
            const {location} = this.state;
            const newLocation = location;
            newLocation.push({
              lat: orgStatusData.latitude ? parseFloat(orgStatusData.latitude) : null,
              long: orgStatusData.longitude ? parseFloat(orgStatusData.longitude) : null,
              radius: orgStatusData.radius ? orgStatusData.radius : null,
              address: orgProfile.address,
              name: orgProfile.name,
            });
            this.setState({
              refreshState: null,
            });
          }
        });
      }
    }

    if (isError && !isObject(message)) toast('error', message);
  }

  componentWillUnmount() {
    this.handleReset();
  }

  handleSubmit = () => {
    const {name, formId, formName, formAddress, formLogo, longitude, latitude, radius, groupEmail, location, pic_name, pic_number, pic_email, npwp_id, npwp_holder} = this.state;
    const newLocation = location.filter((value) => {
      return value !==null;
    });
    if (newLocation.length > 1 && !this.checkLocation()) {
      toast('error', 'Every fields are required');
      return;
    }
    this.setState({errorMessage: null});
    const dataForm = new FormData();
    const checkGroupEmail = this.validateGroupEmail(groupEmail);
    if (npwp_id) dataForm.append('npwp_id', npwp_id);
    if (npwp_holder) dataForm.append('npwp_holder_name', npwp_holder);
    if (formName) dataForm.append('name', formName);
    if (pic_name) dataForm.append('pic_name', pic_name);
    if (pic_number) dataForm.append('pic_number', pic_number);
    if (pic_email) dataForm.append('pic_email', pic_email);
    if (newLocation[0]['address'] || newLocation[0]['address'] === '') dataForm.append('address', newLocation[0]['address']);
    if (formLogo) dataForm.append('logo', formLogo);
    if (newLocation[0]['lat']) dataForm.append('latitude', String(newLocation[0]['lat']));
    if (newLocation[0]['long']) dataForm.append('longitude', String(newLocation[0]['long']));
    if (newLocation[0]['radius']) dataForm.append('radius', newLocation[0]['radius']);
    if (newLocation[0]['lat'] === null) dataForm.append('latitude', '0');
    if (newLocation[0]['long'] === null) dataForm.append('longitude', '0');
    if (newLocation[0]['radius'] === null) dataForm.append('radius', 0);
    if (newLocation.length > 1) dataForm.append('location', JSON.stringify(newLocation));
    if (newLocation.length === 1) dataForm.append('location', '[]');
    this.props.updateOrganization(formId, dataForm);
    if (checkGroupEmail <= 0) {
      dataForm.append('groupEmail', this.getEmail(groupEmail));
    }
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
    console.log(value);
  }

  handleChangeAddress = (event, id) => {
    const {location} = this.state;
    const newLocation = location;
    if (event.target.name === 'address' || event.target.name === 'name') {
      newLocation[id][event.target.name] = event.target.value;
    } else {
      newLocation[id][event.target.name] = event.target.value ? parseFloat(event.target.value) : null;
    }
    this.setState({
      ...location,
      location: newLocation,
    });
  }

  handleChangeNPWP = (event) => {
    const { name, value } = event.target;
    const { errorMessage } = this.state;

    if (name === 'npwp_id') {
      const numericValue = value.replace(/[^0-9]/g, '');
  
      const slicedValue = numericValue.slice(0, 15);
    
      const formattedValue = slicedValue
      .replace(/^(\d{2})(\d{3})/, '$1.$2')
      .replace(/^(\d{2}\.\d{3})(\d{3})/, '$1.$2') 
      .replace(/^(\d{2}\.\d{3}\.\d{3})(\d{1})/, '$1.$2') 
      .replace(/^(\d{2}\.\d{3}\.\d{3}\.\d)(\d{3})/, '$1-$2') 
      .replace(/^(\d{2}\.\d{3}\.\d{3}\.\d-\d{3})(\d{3})/, '$1.$2'); 

      this.setState({
        errorMessage: {
          ...errorMessage,
          npwp_id: null,
        },
        [name]: formattedValue, 
      });
    }
  };

  addMoreAddress = () => {
    const {location} = this.state;
    const newLocation = location;
    const count = location.filter((value) => {
      return value !==null;
    });
    if (count.length <= BRANCH_LIMIT) {
      newLocation.push({
        lat: null,
        long: null,
        radius: 100,
        address: null,
        name: 'Branch '+ location.length,
      });
      this.setState({
        ...location,
        location: newLocation,
      });
    } else {
      toast('info', 'A company branch currently limited up to '+BRANCH_LIMIT);
    }
  }

  removeAddress = async (value) => {
    const {location} = this.state;
    const newLoc = location;
    delete newLoc[value];
    this.setState({location: newLoc});
   }

   checkLocation = () => {
    const {latitude, longitude, radius, errorMessage, location} = this.state;
    const newLocation = location.filter((value) => {
      return value !== null;
    });
    for (let i=0; i < newLocation.length; i++) {
      const validate = () => {
        if (!newLocation[i]['lat'] && !newLocation[i]['long'] && !newLocation[i]['radius'] && !newLocation[i]['address'] && !newLocation[i]['name']) {
          return false;
        } else {
          if (!newLocation[i]['lat']) return false;
          if (!newLocation[i]['long']) return false;
          if (!newLocation[i]['radius']) return false;
          if (newLocation[i]['radius'] < 0) return false;
          if (!newLocation[i]['name']) return false;
          if (!newLocation[i]['address']) return false;
        }
        return true;
      };
      var isValid = validate();
      if (!isValid) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...(!newLocation[i]['lat'] && {latitude: 'All latitude is required'}),
            ...(!newLocation[i]['long'] && {longitude: 'All longitude is required'}),
            ...(!newLocation[i]['radius'] && {radius: 'All radius is required'}),
            ...(newLocation[i]['radius'] < 0 && {radius: 'All radius must be greather then 0'}),
            ...(!newLocation[i]['name'] && {name: 'All name is required'}),
            ...(!newLocation[i]['address'] && {address: 'All address is required'}),
          },
        });
        break;
      }
    }
    return isValid;
  }

  handleImageChange = (event) => {
    const logo = event.target.files[0];
    const imageSizeIsAllowed = checkFileUploadSize(logo);
    if (imageSizeIsAllowed) {
      this.setState({
        errorImageSize: null,
        formLogo: logo,
        logoURL: URL.createObjectURL(logo),
      });
    } else {
      let message;
      if (imageSizeIsAllowed === false) message = 'Maximum file size is ' + process.env.REACT_APP_MAX_UPLOAD_SIZE_MB + ' MB';
      if (imageSizeIsAllowed === null) message = 'Please select image';
      this.setState({
        errorImageSize: message,
      });
    }
  };

  handleEmailGroup = (event, index) => {
    const {value} = event.target;
    const {groupEmail} = this.state;
    const newGroupEmail = groupEmail.map((val, i) => {
      if (index === i) {
        val.email = value;
        val.error = '';
      }
      return val;
    });
    this.setState({groupEmail: newGroupEmail});
  }

  handleReset = () => {
    this.setState(this.initialState);
  }

  renderForm = () => {
    const {classes, isLoading, message} = this.props;
    const {formName, formAddress, logoURL, errorImageSize, latitude, longitude, radius, errorMessage, location, pic_name, pic_number, pic_email, npwp_id, npwp_holder} = this.state;

    return (
      <form>
        {featureFlag.companyProfile.enable_npwp ? (
          <>
            <h5 className={classes.companySubTitle}>NPWP</h5>
            <MInput
              classNameFC={classes.formControl}
              name="npwp_holder"
              label={'NPWP Holder Name'}
              placeholder="Update your NPWP holder name"
              value={npwp_holder}
              onChange={this.handleChangeNPWP}
              autoFocus
              autoComplete="off"
              error={!npwp_holder && errorMessage && errorMessage.npwp_holder ? errorMessage.npwp_holder : undefined}
            />
            <MInput
              classNameFC={classes.formControl}
              name="npwp_id"
              label={'NPWP ID'}
              placeholder="Update your NPWP ID name"
              value={npwp_id}
              onChange={this.handleChangeNPWP}
              autoFocus
              autoComplete="off"
              error={!npwp_id && errorMessage && errorMessage.npwp_id ? errorMessage.npwp_id : undefined}
            />
          </>
        ) : null}
        <h5 className={classes.companySubTitle}>Company Name</h5>
        <MInput
          classNameFC={classes.formControl}
          name="formName"
          label={'Name'}
          placeholder="Update your company name"
          value={formName}
          onChange={this.handleChange}
          autoFocus
          autoComplete="off"
        />
        <h5 className={classes.companySubTitle}>PIC Company</h5>
        <MInput
          classNameFC={classes.formControl}
          name="pic_name"
          label={'PIC Name'}
          placeholder="PIC Name"
          value={pic_name}
          onChange={this.handleChange}
          autoFocus
          autoComplete="off"
        />
        <MInput
          classNameFC={classes.formControl}
          name="pic_number"
          label={'PIC Number'}
          placeholder="PIC number"
          value={pic_number}
          onChange={this.handleChange}
          autoFocus
          autoComplete="off"
          type={'number'}
        />
        <MInput
          classNameFC={classes.formControl}
          name="pic_email"
          label={'PIC Email'}
          placeholder="PIC Email"
          value={pic_email}
          onChange={this.handleChange}
          autoFocus
          autoComplete="off"
          type={'email'}
        />
        <h5 className={classes.companyAddress}>Company Address</h5>
        {
          !isLoading && location ? location.map((value, idx) => {
            const newAddress = _.isNaN(value.address) ? value.address.toString() : value.address;
            const newName = _.isNaN(value.name) ? value.name.toString() : value.name;
            return <Card variant="outlined" className={classes.cardCompany} key={idx}>
              <CardContent>
                { idx !==0 ?
                  newName ?
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={12}>
                      <MInput
                        classNameFC={classes.formControl}
                        name="name"
                        label={'Name'}
                        placeholder="Branch name"
                        value={newName}
                        onChange={(event) => this.handleChangeAddress(event, idx)}
                        autoFocus={!newName}
                        autoComplete="off"
                        error={!newName && errorMessage && errorMessage.name ? errorMessage.name : undefined}
                      />
                    </Grid>
                  </Grid> :
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={12}>
                      <MInput
                        classNameFC={classes.formControl}
                        name="name"
                        label={'Name'}
                        placeholder="Branch name"
                        value={newName}
                        onChange={(event) => this.handleChangeAddress(event, idx)}
                        autoFocus={!newName}
                        autoComplete="off"
                        error={!newName && errorMessage && errorMessage.name ? errorMessage.name : undefined}
                      />
                    </Grid>
                  </Grid> :
                null }
                {newAddress ?
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={12}>
                      <MInput
                        classNameFC={classes.formControl}
                        name="address"
                        label={'Address'}
                        placeholder="Update your company address"
                        value={newAddress}
                        onChange={(event) => this.handleChangeAddress(event, idx)}
                        autoFocus={!newAddress}
                        autoComplete="off"
                        error={!newAddress && errorMessage && errorMessage.address ? errorMessage.address : undefined}
                      />
                    </Grid>
                  </Grid> :
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={12}>
                      <MInput
                        classNameFC={classes.formControl}
                        name="address"
                        label={'Address'}
                        placeholder="Update your company address"
                        value={newAddress}
                        onChange={(event) => this.handleChangeAddress(event, idx)}
                        autoFocus={!newAddress}
                        autoComplete="off"
                        error={!newAddress && errorMessage && errorMessage.address ? errorMessage.address : undefined}
                      />
                    </Grid>
                  </Grid>
                }
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={4}>
                    <MInput
                      classNameFC={classes.formControl}
                      name="lat"
                      label={'Latitude'}
                      placeholder="Input latitude"
                      value={value.lat}
                      onChange={(event) => this.handleChangeAddress(event, idx)}
                      autoComplete="off"
                      error={!value.lat && errorMessage && errorMessage.latitude ? errorMessage.latitude : undefined}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MInput
                      classNameFC={classes.formControl}
                      name="long"
                      label={'Longitude'}
                      placeholder="Input longitude"
                      value={value.long}
                      onChange={(event) => this.handleChangeAddress(event, idx)}
                      autoComplete="off"
                      error={!value.long && errorMessage && errorMessage.longitude ? errorMessage.longitude : undefined}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MInput
                      classNameFC={classes.formControl}
                      name="radius"
                      label={'GPS Radius (meter)'}
                      placeholder="Max GPS radius"
                      type="number"
                      value={value.radius}
                      autoComplete="off"
                      onChange={(event) => this.handleChangeAddress(event, idx)}
                      error={!value.radius && errorMessage && errorMessage.radius ? errorMessage.radius : undefined}
                    />
                  </Grid>
                </Grid>
                { idx !==0 ?
                  <MButton
                    variant="contained"
                    label="Remove"
                    startIcon={<Delete />}
                    onClick={() => this.removeAddress(idx)}
                    className={[classes.mb10, classes.btnRemove, classes.formControl, classes.mr30]}
                  /> :
                  null }
              </CardContent>
            </Card>;
          }) : null
        }
        <Grid container>
          {
              <>
                <MButton
                  className={classes.btnAddMore}
                  variant="contained"
                  label="Add More"
                  startIcon={<Add />}
                  onClick={this.addMoreAddress}
                />
              </>
          }
        </Grid>
        <h5 className={classes.companySubTitle}>Company Logo</h5>
        <MInput
          name="formLogo"
          label="Logo"
          type="file"
          onChange={this.handleImageChange}
          error={
            errorImageSize ?
            errorImageSize :
            (
              message && message.logo ?
              message.logo :
              undefined
            )
          }
          inputProps={{accept: 'image/*'}}
        />
        {
          logoURL &&
          <CardMedia
            title="Photo"
            image={logoURL}
            className={classes.photo}
            style={{marginBottom: '8px'}}
          />
        }
        <MButton
          className={classes.defaultBtn}
          label="Update Profile"
          icon={<Send/>}
          loading={isLoading}
          onClick={this.handleSubmit}
        />
      </form>
    );
  }

  renderGroupEmail = () => {
    const {groupEmail} = this.state;
    return groupEmail.map((value, index) => {
      const {isLoading} = this.props;
      return (
        <Grid container key={index.toString()}>
          <Grid xs={12}>
            <MInput
              defaultValue={value.email}
              value={value.email}
              placeholder="Input email"
              name="groupEmail"
              label="Email"
              onChange={(valInput) => this.handleEmailGroup(valInput, index)}
              isLoading={isLoading}
              onButtonCustom={() => this.removeGroupEmail(index)}
              error={value.error ? value.error : false}
            />
          </Grid>
        </Grid>
      );
    });
  }

  addGroupEmail = () => {
    const {groupEmail} = this.state;
    const maxEmail = 3;
    if (groupEmail.length >= maxEmail) {
      toast('error', 'Maximal email that can be input are '+maxEmail);
    } else {
      groupEmail.push({email: '', error: ''});
      this.setState({groupEmail});
    }
  }

  removeGroupEmail = (index) => {
    const {groupEmail} = this.state;
    const minEmail = 1;
    if (groupEmail.length <= minEmail) {
      toast('error', 'Minimal email that can be input is '+minEmail);
    } else {
      const newGroupEmail = groupEmail.filter((value, i) => {
        return index!==i;
      });
      this.setState({groupEmail: newGroupEmail});
    }
  }

  validateGroupEmail = (array) => {
    const results = [];
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const groupEmail = array.map((val) => {
      if (!val.email || val.email==='') {
        val.error = 'This email is required';
        results.push(val);
      } else if (!regex.test(val.email)) {
        val.error = 'This email is invalid';
        results.push(val);
      }
      return val;
    });
    this.setState({groupEmail});
    return results.length;
  }

  getEmail = (array) => {
    const groupEmail = array.map((val) => {
      delete val.error;
      return val;
    });
    return groupEmail;
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classes.root}>
        <Paper variant='outlined' className={classes.paper}>
          {this.renderForm()}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = ({auth, organization}) => ({
  orgProfile: auth.data.organization,
  isLoading: organization.isLoading,
  isSuccess: organization.isSuccess,
  isError: organization.isError,
  message: organization.message,
  trigger: organization.trigger,
  myCompanyDetail: organization.orgProfile,
  orgStatusData: organization.orgStatusData,
});

const mapDispatchToProps = (dispatch) => ({
  getMyOrganizationProfile: () => dispatch(getMyOrganizationProfile()),
  updateOrganization: (id, params) => dispatch(updateOrganization(id, params)),
  updateMyProfile: (params) => dispatch(updateMyProfile(params)),
  checkStatus: () => dispatch(checkStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CompanyProfile));

