import featureFlag from '../../config/featureFlag';

export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    paddingTop: 2,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 24,
    color: theme.palette.text.secondary,
    marginBottom: 36,
    borderRadius: 18,
    boxShadow: 'none',
  },
  formControlSearchBy: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 150,
  },
  formControlStatus: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 100,
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  title: {
    lineHeight: 0,
    marginBottom: 30,
    fontSize: '1.8em',
  },
  tableHead: {
    fontWeight: 'bold',
  },
  checkInTrue: {
    color: '#008000',
    fontSize: 20,
  },
  checkInFalse: {
    color: '#F00B38',
    fontSize: 20,
  },
  tableHeaderIcon: {
    fontSize: 30,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableTitle: {
    fontSize: '18px',
    fontWeight: '700',
    color: '#656464',
    fontFamily: 'Poppins !important',
    marginTop: '-2px !important',
    marginLeft: '-8px !important',
  },
  tableTooltip: {
    fontSize: '14px',
  },
  modalTitle: {
    textalign: 'center',
    fontWeight: 600,
  },
  fade: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 600,
  },
  modalItemSpacing: {
    margin: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
    color: 'black',
  },
  modalAvatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  modalCloseBtn: {
    float: 'right',
    marginBottom: 10,
  },
  dialogContent: {
    padding: 20,
  },
  cardColor: {
    backgroundColor: '#ffffff',
    width: '70%',
  },
  imgSize: {
    width: 128,
    height: 128,
  },
  modalStyle1: {
    overflow: 'scroll',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterBtn: {
    'marginTop': 22,
    'marginRight': 20,
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  filterBtnEnd: {
    marginTop: '22px',
    marginRight: 0,
  },
  filter: {
    marginRight: 25,
  },
  btnFilterContainer: {
    display: 'inline-flex',
  },
  filterDatePicker: {
    marginRight: 25,
    maxWidth: 140,
  },
  detailBtn: {
    'backgroundColor': '#00AFF0',
    'color': '#fff',
    'boxShadow': 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#00A2DE',
    },
  },
  searchIcon: {
    position: 'absolute',
    right: 0,
  },
  btnExport: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
  },
  tableImg: {
    height: 50,
    width: 50,
  },
  defaultBtn: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'marginTop': '5px',
    'marginBottom': '5px',
    'marginRight': '24px',
  },
  defaultBtnReject: {
    'backgroundColor': '#00AFF0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#00A2DE',
      boxShadow: 'none',
    },
    'color': '#fff',
    'marginTop': '5px',
    'marginBottom': '5px',
    'marginRight': '13px',
  },
  cancelBtn: {
    'backgroundColor': '#e0e0e0',
    'boxShadow': 'none',
    '&:hover': {
      backgroundColor: '#d5d5d5',
      boxShadow: 'none',
    },
    'color': 'rgba(0, 0, 0, 0.87);',
    'marginTop': '5px',
    'marginBottom': '5px',
  },
  accordionTitle: featureFlag.permit.enable_approval ? {
    fontSize: 14,
    fontWeight: '600',
    color: '#656464',
  } : {
    fontSize: 14,
    fontWeight: '600',
    color: '#656464',
  },
  statusRequest: featureFlag.permit.enable_approval ? {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  } : {
    fontSize: 14,
    fontWeight: '700',
    color: '#00A2DE',
  },
  statusOngoing: featureFlag.permit.enable_approval ? {
    fontSize: 14,
    fontWeight: '700',
    color: '#FFC226',
  } : {
    fontSize: 14,
    fontWeight: '700',
    color: '#FFC226',
  },
  statusRejected: featureFlag.permit.enable_approval ? {
    fontSize: 14,
    fontWeight: '700',
    color: '#F00B38',
  } : {
    fontSize: 14,
    fontWeight: '700',
    color: '#F00B38',
  },
  statusCompleted: featureFlag.permit.enable_approval ? {
    fontSize: 14,
    fontWeight: '700',
    color: '#008000',
  } : {
    fontSize: 14,
    fontWeight: '700',
    color: '#008000',
  },
  timelineDate: featureFlag.permit.enable_approval ? {
    fontSize: 12,
    fontWeight: '400',
    color: '#737373',
  } : {
    fontSize: 12,
    fontWeight: '400',
    color: '#737373',
  },
});
