/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import styles from './styles';
import {
  Button,
  Grid,
  Avatar,
  Divider,
  Paper,
  CardMedia,
  Typography,
  Link,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  createTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
  DialogActions,
} from '@material-ui/core';
import {
  CheckCircleOutline,
  Search,
  SaveAlt,
  Refresh,
  WarningOutlined,
  ArrowBack,
  CancelOutlined,
  Publish,
  Send,
} from '@material-ui/icons';
import {toast, isObject, convDate, isArray} from '../../helpers';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {MInput, MKeyBoardDatepicker, MButton, ModalTitle, MSelect} from '../../components/form';
import {Datatable} from '../../components';
import _ from 'lodash';
import moment from 'moment';
import {ATTENDANCE_STATE as STATE} from '../../redux/attendanceHistory/attendance.types';
import {
  getAttendanceHistory,
  getAttendanceDetail,
  exportExcel,
  importExcel,
  downloadExcelTemplate,
} from './../../redux/attendanceHistory/attendance.actions';
import {checkStatus} from './../../redux/userManagement/userManagement.actions';
import {Checkbox, FormControlLabel} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import {Snackbar, createTheme as themeTemplate} from '@mui/material';
import {updateExportNotification} from '../../redux/exportActivity/exportActivity.actions';
import {updateImportNotification} from '../../redux/importActivity/importActivity.actions';
import {getGeneralSetting} from '../../redux/generalSetting/generalSetting.actions';
import {GENERAL_SETTING_STATE} from '../../redux/generalSetting/generalSetting.types';
import {DropzoneArea} from 'material-ui-dropzone';
import organization from '../organization';
import RenderGlobalFilter from '../../components/TopFIlter';
import featureFlag from '../../config/featureFlag';

class AttendanceHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 10,
      query: null,
      startDate: null,
      endDate: null,
      sortBy: null,
      order: 'desc',
      method: 'all',
      completed: null,
      showDetail: false,
      resetDatatable: null,
      detailData: null,
      resetPage: null,
      errorMessage: null,
      errorFilterDate: false,
      filterCheckbox: false,
      businessData: [],

      snackbar: false,
      snackbarVertical: 'top',
      snackbarHorizontal: 'right',
      take_photo_office: false,
      modalImportShow: false,
      file: null,
    };
  }

  checkboxTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
  })

  snackbarTheme = () => themeTemplate({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            color: '#000000 !important',
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
  });

  tooltipTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '18px !important',
            marginLeft: '6px !important',
            marginTop: '-1px !important',
          },
        },
      },
    },
  })

  dialogTheme = () => createMuiTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiDialogTitle: {
        root: {
          display: 'flex',
          justifyContent: 'center',
        },
      },
    },
  })

timelineTheme = () => createTheme({
  palette: {
    primary: {
      main: '#00AFF0',
    },
    secondary: {
      main: '#FFC226',
    },
    warning: {
      main: '#F00B38',
    },
    success: {
      main: '#008000',
    },
  },
  components: {
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: '46px !important',
          alignItems: 'center',
        },
      },
    },
    MuiTimelineContent: {
      styleOverrides: {
        root: {
          padding: '6px 4px 6px 12px !important',
        },
      },
    },
    MuiTimeline: {
      styleOverrides: {
        root: {
          padding: '6px 16px 0px 16px !important',
        },
      },
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: '22px 0px !important',
        },
      },
    },
  },
})

  accordionTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          'content': {
            display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            margin: '10px 0px 8px 0px !important',
            padding: '10px 0px',
          },
          '&.Mui-expanded': {
            // display: 'flex',
            justifyContent: 'space-between',
            minHeight: '0px !important',
            // margin: '8px 0px 0px 0px !important',
          },
          'root': {
            minHeight: '0px !important',
          },
        },
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            display: '',
            padding: '0px !important',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: '8px 4px 8px 12px !important',
            marginTop: '6px !important',
          },
        },
      },
    },
  })


  UNSAFE_componentWillMount() {
    this.props.checkStatus(); // check company feature status availability {remote, business, office}
  }


  UNSAFE_componentWillReceiveProps(nextProps) {
    const {trigger, triggerSetting, isSuccess, isError, exportData, importData, message, generalSetting, importLoading, isLoading} = nextProps;

    if (trigger === STATE.EXPORT_SUCCESS && isSuccess) {
      // toast('success', 'File successfully exported & downloaded');
      this.setState({snackbar: true});
      this.props.updateExportNotification({
        fileName: 'Attendance History',
        ...exportData,
      });
    }

    if (trigger === STATE.IMPORT_SUCCESS && isSuccess) {
      const queueImport = {
        fileName: 'Historical Attendance Import',
        status: 'waiting',
        type: 'import',
      };
      this.setState({modalImportShow: false});

      this.props.updateImportNotification(queueImport);
    }

    if (isError && message && isObject(message)) this.setState({errorMessage: message});
    if (isError && message && !isObject(message)) toast('error', message);
  }

  componentDidMount() {
    this.handleCheckQueryParam();
    const url_string = window.location.href;
    const url = new URL(url_string);
    const name = url.searchParams.get('query');

    if (name) {
      this.setState({query: name}, () => {
        this.handleDebounceSearch(name);
      });
    }
  }

  fetchAttendance = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed} = this.state;
    this.props.getAttendanceHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(method && method !== 'all' && {method}),
      ...(completed !== null && completed !== undefined && {completed}),
      status: 'approved',
    });
  }

  handleCloseSnackbar = () => {
    this.setState({snackbar: false});
  };

  renderSnackbar = () => {
    const {snackbar, snackbarVertical, snackbarHorizontal} = this.state;
    const action = (
      <Button color="primary" size="small" onClick={() => this.props.history.push('/export-activity')}>
        View Activity
      </Button>
    );
    return (
      <ThemeProvider theme={this.snackbarTheme()}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={snackbar}
          onClose={this.handleCloseSnackbar}
          message="Your file is in the queue, click here to see the progress"
          key={snackbarVertical + snackbarHorizontal}
          action={action}
        />
      </ThemeProvider>
    );
  }

  handleCheckQueryParam = () => {
    const queryParam = this.props.location.search.substring(1);
    if (queryParam) {
      const searchParam = new URLSearchParams(queryParam);
      const range = searchParam.get('range');
      const method = searchParam.get('method');
      const completed = searchParam.get('completed');
      let startDate;
      let endDate;

      switch (range) {
        case 'today':
          startDate = moment();
          endDate = moment();
          break;
        case 'yesterday':
          startDate = moment().add(-1, 'days');
          endDate = moment().add(-1, 'days');
          break;
        case 'lastWeek':
          // startDate = moment().subtract(1, 'weeks').startOf('isoWeek'); // last week (mon - sun)
          // endDate = moment().subtract(1, 'weeks').endOf('isoWeek');

          startDate = moment().add(-7, 'days'); // last 7 days
          endDate = moment().add(-1, 'days');
          break;
        case 'lastMonth':
          startDate = moment().subtract(1, 'months').startOf('month');
          endDate = moment().subtract(1, 'months').endOf('month');
          break;
        default:
          break;
      }

      this.setState({
        startDate,
        endDate,
        ...(completed && {completed}),
        ...(method && {method}),
      },
      () => {
        this.fetchAttendance();
      });
    } else {
      this.fetchAttendance();
    }
  }
  toggleModal = (type, data) => {
    const {attendanceData, activeBusinessCheckout} = this.props;
    const {businessData} = this.state;
    const newData = [];
    if (type === 'detail') {
      const numberId = data.rowData[0]; // get detail attendance from datatable response, based on index
      const selected = attendanceData.filter((data) => data.no === numberId);
      let detailData = [];
      detailData = selected && selected.length ? selected[0] : null;
      if (detailData.method === 'business' && activeBusinessCheckout) {
        detailData.data.map((data, index) => {
          newData.push(
              {
                ...data,
                trip: detailData.data.length - index,
                open: false,
              },
          );
        });
      }
      this.setState({businessData: newData});
      this.setState({showDetail: true, detailData});
    } else if (type === 'close') {
      this.setState({showDetail: false, detailData: null});
    }
  };

  toggleResetDatatable = (status) => {
    this.setState(
        {resetDatatable: status},
        () => this.setState({resetDatatable: null}),
    );
  }

  toggleResetPage = () => {
    this.setState({resetPage: true, page: 1, limit: 10}, () =>
      this.setState({resetPage: null}),
    );
  };


  handleReset = () => {
    const state = {
      page: 1,
      limit: 10,
      query: '',
      startDate: convDate(moment()),
      endDate: convDate(moment()),
      sortBy: null,
      order: 'desc',
      method: 'all',
      complete: null,
      detailData: null,
      errorFilterDate: false,
    };
    this.setState(state, () => this.fetchAttendance());
    this.toggleResetDatatable(true);
    this.toggleResetPage();
  }

  handleReload = (params) => {
    this.setState(
        params,
        () => this.fetchAttendance(),
    );
  }

  handleExport = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed} = this.state;
    this.props.exportExcel({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(method && method !== 'all' && {method}),
      ...(completed !== null && completed !== undefined && {completed}),
      status: 'approved',
    });
  }


  handleFilterChange = (event) => {
    const {value, name} = event.target;
    this.setState(
        {[name]: value, page: null},
    );
  }

  handleFilterDateChange = (params) => {
    const {value, name} = params;
    this.setState(
        {[name]: value, page: null},
    );
  }

  handleSearch = (event) => {
    const {value} = event.target;
    this.setState({query: value});
    this.handleDebounceSearch(value);
  }

  handleFilterCheckbox = (event) => {
    const {checked} = event.target;

    if (checked) {
      this.setState({
        filterCheckbox: checked,
        startDate: convDate(moment()),
        endDate: convDate(moment()),
      });
    } else {
      this.setState({
        filterCheckbox: checked,
        startDate: convDate(moment()),
        endDate: convDate(moment()),
      });
      const state = {
        page: 1,
        limit: 10,
        query: '',
        startDate: null,
        endDate: null,
        sortBy: null,
        order: 'desc',
        method: 'all',
        complete: null,
        detailData: null,
        errorFilterDate: false,
      };
      this.setState(state, () => this.fetchAttendance());
      this.toggleResetDatatable(true);
      this.toggleResetPage();
    }
  }

  handleDebounceSearch = _.debounce((query) => {
    this.toggleResetPage();
    this.setState(
        {page: null},
        () => this.fetchAttendance(),
    );
  }, 500);

  handleButtonFilter = () => {
    const {page, limit, startDate, endDate, query, sortBy, order, method, completed} = this.state;

    if (startDate === null || endDate === null) {
      return this.setState({
        errorFilterDate: true,
      });
    }

    this.props.getAttendanceHistory({
      ...(page && {page}),
      ...(limit && {limit}),
      ...(query && {query}),
      ...(startDate && {start_date: convDate(startDate)}),
      ...(endDate && {end_date: convDate(endDate)}),
      ...(sortBy && {sort_by: sortBy}),
      ...(order && {order}),
      ...(method && method !== 'all' && {method}),
      ...(completed !== null && completed !== undefined && {completed}),
      status: 'approved',
    });
  }

  handleModalImport = () => {
    // const props = this.props;

    this.setState({
      modalImportShow: !this.state.modalImportShow,
      // import_users: orgStatusData && orgStatusData.import_users,
    });
  };

  handleImportFile = (files) => {
    this.setState({file: files}, () => console.log(this.state.file));
  }

  handleSubmitImport = (e) => {
    e.preventDefault();
    const {importExcel} = this.props;
    const {file} = this.state;

    const excel = file.length > 0 && file[0] ? file[0] : null;
    const dataForm = new FormData();

    if (excel) dataForm.append('files', excel);

    importExcel(dataForm);
  };

  renderModalImport = () => {
    const {classes, importLoading} = this.props;
    const {modalImportShow, file} = this.state;

    // const template = window.location.origin +'/download/format-import-backdate-attendance.xlsx';

    return (
      <Dialog
        open={modalImportShow}
        onClose={() => this.handleModalImport()}
        maxWidth='md'
      >
        <ModalTitle
          title={'Import Attendance History'}
          onClose={() => this.handleModalImport()}
        />
        <form onSubmit={this.handleSubmitImport} autoComplete="off">
          <DialogContent className={classes.dialogContent}>
            <DropzoneArea
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{container: {spacing: 1, direction: 'row'}}}
              previewChipProps={{classes: {root: classes.previewChip}}}
              previewText="Selected files"
              dropzoneText='Drag and drop an Excel (.csv/.xls/.xlsx) file here or click'
              filesLimit={1}
              classes={{root: `${file && file.length > 0 ? classes.hidden : classes.dropZoneArea}`, text: classes.dropZoneText, icon: classes.dropZoneIcon}}
              acceptedFiles={['.csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
              onChange={(files) => this.handleImportFile(files)}
            />
            <div style={{marginTop: 24}}>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Link
                  download
                  target="_blank"
                  onClick={() => this.props.downloadExcelTemplate()}
                >
                  <Typography
                    className={classes.guideLinkPopup}
                    color='primary'
                    style={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                  >
                    Download Excel Template
                  </Typography>
                </Link>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Grid container justifyContent='space-between'>
              <Grid item spacing={2}>
                <MButton
                  label="Cancel"
                  style={{marginRight: 15}}
                  onClick={() => this.handleModalImport()}
                />
                <MButton
                  className={classes.defaultBtn}
                  label={'Import'}
                  type="submit"
                  icon={<Send />}
                  loading={importLoading ? true : false}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  renderMethodOptions = () => {
    const {classes, exportLoading, attendanceTypes, exportData, isSuccess, attendanceData} = this.props;
    const options = [
      {id: 'all', name: 'All'},
    ];
    if (attendanceTypes && attendanceTypes.remote) options.push({id: 'remote', name: 'Remote Working'});
    if (attendanceTypes && attendanceTypes.office) options.push({id: 'office', name: 'Office Working'});
    if (attendanceTypes && attendanceTypes.business) options.push({id: 'business', name: 'Business Trip'});
    if (attendanceTypes && attendanceTypes.overtime) options.push({id: 'overtime', name: 'Overtime'});
    return options;
  };
  renderFilter = () => {
    const {classes, exportLoading, attendanceTypes, exportData, isSuccess, attendanceData} = this.props;
    const {query, startDate, endDate, method} = this.state;

    return (
      <Paper variant='outlined' className={classes.paper}>
        {this.state.filterCheckbox ? (
          <>
            <MInput
              autoFocus
              className={`${classes.filter} ${classes.searchInput}`}
              fullWidth={false}
              variant="standard"
              name="query"
              label="Search"
              placeholder="Search keyword"
              value={query}
              onChange={this.handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                ),
              }}
            />
            <ThemeProvider theme={this.checkboxTheme()}>
              <FormControlLabel
                style={!this.state.filterCheckbox ?{marginTop: 22} : {marginRight: 24, marginTop: 22}}
                control={
                  <Checkbox
                    color='primary'
                    checked={this.state.filterCheckbox}
                    onChange={(e) => this.handleFilterCheckbox(e)}
                  />
                }
                label="Filter"
              />
            </ThemeProvider>
            <MSelect
              shrink={true}
              fullWidth={false}
              classNameFC={`${classes.formControl} ${classes.filter}`}
              variant="standard"
              name="method"
              label="Type"
              keyPair={['id', 'name']}
              options={this.renderMethodOptions()}
              value={method}
              onChange={this.handleFilterChange}
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="startDate"
              label="Start Date"
              value={startDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              maxDate={endDate ? new Date(endDate) : undefined}
              // error={message && message.start_date ? message.start_date : undefined}
              error={
                this.state.startDate === null && this.state.endDate !== null ? 'Start Date is required' :
                  startDate === null && this.state.errorFilterDate ? 'Start Date is required' :
                    undefined
              }
            />
            <MKeyBoardDatepicker
              className={classes.filterDatePicker}
              name="endDate"
              label="End Date"
              value={endDate}
              onDateChange={this.handleFilterDateChange}
              placeholder="dd-mm-yyyy"
              minDate={new Date(startDate)}
              maxDate={new Date()}
              // error={message && message.end_date ? message.end_date : undefined}
              error={
                this.state.startDate !== null && this.state.endDate === null ? 'End Date is required' :
                  endDate === null && this.state.errorFilterDate ? 'End Date is required' :
                    undefined
              }
            />
            <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Apply"
                color="primary"
                onClick={this.handleButtonFilter}
              />
              <MButton
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh/>}
                onClick={this.handleReset}
              />
              <MButton
                className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                label="Export"
                icon={<SaveAlt/>}
                onClick={this.handleExport}
                loading={exportLoading}
                disabled={attendanceData?.length < 1 ? true : false}
              />
              <Tooltip title="Use the Historical Attendance Import feature to import past attendance data to the system. Download the template, fill it with your historical attendance data, and upload it to the system. If any errors occur during the upload, the system will generate an error document detailing the issues.">
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  style={{marginLeft: '16px'}}
                  label={'Import'}
                  icon={<Publish />}
                  loading={exportLoading}
                  onClick={this.handleModalImport}
                />
            </Tooltip>
              <br />
            </div>
            {/* <Typography style={{margin: '0px 4px 0', justifyContent: 'end', display: (isSuccess && exportData) ? 'flex' : 'none'}}>
              or click &nbsp;
              <Link href={exportData} target="_blank" >this link</Link>
                &nbsp;
                if file corrupt
            </Typography> */}
          </>
        ) : (
          <Grid container justifyContent='space-between'>
            <Grid item>
              <MInput
                autoFocus
                className={`${classes.filter} ${classes.searchInput}`}
                fullWidth={false}
                variant="standard"
                name="query"
                label="Search"
                placeholder="Search keyword"
                value={query}
                onChange={this.handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end">
                      <Search className={classes.searchIcon} />
                    </IconButton>
                  ),
                }}
              />
              <ThemeProvider theme={this.checkboxTheme()}>
                <FormControlLabel
                  style={!this.state.filterCheckbox ?{marginTop: 22} : {marginRight: 24, marginTop: 22}}
                  control={
                    <Checkbox
                      color='primary'
                      checked={this.state.filterCheckbox}
                      onChange={(e) => this.handleFilterCheckbox(e)}
                    />
                  }
                  label="Filter"
                />
              </ThemeProvider>
            </Grid>
            <Grid item>
              <div className={classes.btnFilterContainer}>
                <MButton
                  className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                  label="Export"
                  icon={<SaveAlt/>}
                  onClick={this.handleExport}
                  loading={exportLoading}
                  disabled={attendanceData?.length < 1 ? true : false}
                />
                <br />
              </div>
                <Tooltip title="Use the Historical Attendance Import feature to import past attendance data to the system. Download the template, fill it with your historical attendance data, and upload it to the system. If any errors occur during the upload, the system will generate an error document detailing the issues.">
                  <div className={classes.btnFilterContainer} style={{marginLeft: '16px'}}>
                    <MButton
                      className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                      label={'Import'}
                      icon={<Publish />}
                      loading={exportLoading}
                      onClick={this.handleModalImport}
                    />
                  </div>
                </Tooltip> 
              {/* <Typography style={{margin: '0px 4px 0', justifyContent: 'end', display: (isSuccess && exportData) ? 'flex' : 'none'}}>
                or click &nbsp;
                <Link href={exportData} target="_blank" >this link</Link>
                  &nbsp;
                  if file corrupt
              </Typography> */}
            </Grid>
          </Grid>
        )}
      </Paper>
    );
  }

  toggleTimelineAccordion = (data, index) => {
    const {businessData} = this.state;
    const newData = [];
    businessData.map((data, number) => {
      if (index === number) {
        newData.push({
          ...data,
          open: !data.open,
        });
      } else {
        newData.push({
          ...data,
          open: false,
        });
      }
    });
    this.setState({
      businessData: newData,
    });
  }

  renderModalDetail = () => {
    const {classes, activeBusinessCheckout} = this.props;
    const {showDetail, detailData, businessData} = this.state;

    const name = detailData && detailData.name ? detailData.name : '-';
    const date = detailData && detailData.date ? convDate(detailData.date, 'D MMMM YYYY') : '-';
    const checkIn = detailData && detailData.check_in ? detailData.check_in : '-';
    const checkInImg = detailData?.check_in_img;
    const checkOut = detailData && detailData.check_out ? detailData.check_out : '-';
    const checkOutImg = detailData?.check_out_img;
    const checkInAddress = detailData && detailData.check_in_address ? detailData.check_in_address : '-';
    const checkOutAddress = detailData && detailData.check_out_address ? detailData.check_out_address : '-';
    const todo = detailData && detailData.todo ? detailData.todo : '-';
    const day = detailData && detailData.day ? detailData.day : '-';
    const method = detailData && detailData.method ? detailData.method : null;
    const break_in = detailData && detailData.break_in ? detailData.break_in : null;
    const break_out = detailData && detailData.break_out ? detailData.break_out : null;
    const approvedBy =
      detailData && detailData.approved_by ? detailData.approved_by : '-';
    const columns = ['Check In', 'Address', 'Todo', 'Photo'];
    const checkInLocation = detailData && detailData.check_in_location ? detailData.check_in_location : null;
    const checkOutLocation = detailData && detailData.check_out_location ? detailData.check_out_location : null;

    return (
      <MuiThemeProvider theme={this.dialogTheme()}>

        <Dialog
          open={showDetail}
          onClose={() => this.toggleModal('close')}
          maxWidth='md'
          fullWidth={true}
          scroll='paper'
          PaperProps={{
            style: {borderRadius: 15, paddingTop: 20, paddingBottom: 20},
          }}
        >
          <ModalTitle
            className={classes.modalTitle}
            title="Attendance Detail"
            onClick={() => this.toggleModal('close')}
            style={{display: 'flex', alignSelf: 'center'}}
          />
          <DialogContent className={classes.dialogContent}>
            <div style={method === 'business' ? {padding: '0px 18px 0px 18px'} : null}>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Name </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{name}</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Day </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{day}</Typography></Grid>
              </Grid>
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs={2}><Typography>Date </Typography></Grid>
                <Grid item><Typography> : </Typography></Grid>
                <Grid item xs zeroMinWidth><Typography>{convDate(date, 'D MMMM YYYY')} </Typography></Grid>
              </Grid>

              {method === 'business' && activeBusinessCheckout ? (
                <Grid container wrap="nowrap" spacing={2}>
                  <Grid item xs={2}><Typography className={classes.historyText} style={{marginTop: 12, marginBottom: -12}}>History </Typography></Grid>
                </Grid>
              ) : (null)}
            </div>

            {
              method !== 'business' ?
              (
                <div>
                  {method === 'remote' ? (<Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={2}><Typography>Todo</Typography></Grid>
                    <Grid item><Typography> : </Typography></Grid>
                    <Grid item xs zeroMinWidth><Typography> <pre style={{margin: 0}}>{todo}</pre></Typography></Grid>
                  </Grid>) : null}
                  <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs={2}><Typography>Checkin Location</Typography></Grid>
                    <Grid item><Typography> : </Typography></Grid>
                    {checkInLocation ? <Grid item><Typography>{checkInLocation}</Typography></Grid> : null}
                    <Grid item xs zeroMinWidth><Typography>{checkInAddress}</Typography></Grid>
                  </Grid>
                  <Grid container wrap="nowrap" spacing={2} mb={5}>
                    <Grid item xs={2}><Typography>Checkout Location</Typography></Grid>
                    <Grid item><Typography> : </Typography></Grid>
                    {checkOutLocation ? <Grid item><Typography>{checkOutLocation}</Typography></Grid> : null}
                    <Grid item xs zeroMinWidth><Typography>{checkOutAddress}</Typography></Grid>
                  </Grid>
                  {method === 'office' ? (
                    <>
                      <Grid container wrap="nowrap" spacing={2}>
                        <Grid item xs={2}><Typography>Check In</Typography></Grid>
                        <Grid item><Typography> : </Typography></Grid>
                        <Grid item xs zeroMinWidth><Typography>{checkIn}</Typography></Grid>
                      </Grid>
                      <Grid container wrap="nowrap" spacing={2} mb={5}>
                        <Grid item xs={2}><Typography>Check Out</Typography></Grid>
                        <Grid item><Typography> : </Typography></Grid>
                        <Grid item xs zeroMinWidth><Typography>{checkOut}</Typography></Grid>
                      </Grid>
                    </>
                  ) : null}
                  {method !== 'overtime' ? (
                  <>
                    {
                      break_in || break_out ? (
                        <>
                          <Grid container wrap="nowrap" spacing={2}>
                            <Grid item xs={2}><Typography>Break In</Typography></Grid>
                            <Grid item><Typography> : </Typography></Grid>
                            <Grid item xs zeroMinWidth><Typography>{break_in ? break_in : '-'}</Typography></Grid>
                          </Grid>
                          <Grid container wrap="nowrap" spacing={2} mb={5}>
                            <Grid item xs={2}><Typography>Break Out</Typography></Grid>
                            <Grid item><Typography> : </Typography></Grid>
                            <Grid item xs zeroMinWidth><Typography>{break_out ? break_out : '-'}</Typography></Grid>
                          </Grid>
                        </>
                      ) : null
                    }
                  </>
                  ): (
                    <Grid container wrap="nowrap" spacing={2} mb={5}>
                      <Grid item xs={2}><Typography>Approved By</Typography></Grid>
                      <Grid item><Typography> : </Typography></Grid>
                      <Grid item xs zeroMinWidth><Typography>{approvedBy}</Typography></Grid>
                    </Grid>
                  )}

                </div>
              ) : ''
            }
            <br />
            <Divider />
            {
              method === 'business' && !activeBusinessCheckout?
              (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {
                          columns.map((name, index) => (
                            <TableCell key={index.toString()}>{name}</TableCell>
                          ))
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        detailData.data.map((row) => (
                          <TableRow key={row.check_in_id}>
                            <TableCell component="th" scope="row">{row.check_in}</TableCell>
                            <TableCell component="th" scope="row">{row.check_in_address}</TableCell>
                            <TableCell component="th" scope="row">{row.todo}</TableCell>
                            <TableCell align="right">
                              <Link href={row.check_in_img} target="_blank" rel="noopener noreferrer">
                                <CardMedia
                                  className={classes.tableImg}
                                  alt="check in picture"
                                  image={row.check_in_img}
                                  component='image'
                                />
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (null) }
            {
              method === 'business' && activeBusinessCheckout?
              (
                <ThemeProvider theme={this.timelineTheme()}>
                  <Timeline
                    sx={{
                      [`& .${timelineItemClasses.root}:before`]: {
                        flex: 0,
                        padding: 0,
                      },
                    }}
                  >
                    {businessData.map((data, index) => {
                      return (
                        <TimelineItem key={index.toString()}>
                          <TimelineSeparator>
                            <TimelineDot color={'primary'}/>
                            {index === detailData.data?.length - 1 ? (null) : (<TimelineConnector />)}
                          </TimelineSeparator>
                          <TimelineContent>

                            <ThemeProvider theme={this.accordionTheme()}>
                              <Accordion expanded={data.open} onClick={() => this.toggleTimelineAccordion(data, index)} variant='outlined'>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography className={classes.accordionTitle}>
                                    {`Trip ${data.trip}`}
                                  </Typography>
                                  <Typography
                                    className={
                                      data.check_out === null ? classes.statusOngoing : data.checkout !== null ? classes.statusCompleted : classes.accordionTitle
                                    }
                                  >
                                    {data.check_out === null ? 'On Going' : data.checkout !== null ? 'Completed' : '-'}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Divider style={{marginBottom: 8}} />
                                  <div style={{padding: '8px 16px 14px 16px'}}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography className={classes.accordionDetail}>Check In Time</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className={classes.accordionDetail}>:</Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Typography className={classes.accordionDetail}>{data.check_in ? `${data.date} ${data.check_in}` : '-'}</Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography className={classes.accordionDetail}>Check Out Time</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className={classes.accordionDetail}>:</Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Typography className={classes.accordionDetail}>{data.check_out ? `${data.check_out}` : '-'}</Typography>
                                      </Grid>
                                    </Grid>

                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography className={classes.accordionDetail}>Check In Location</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className={classes.accordionDetail}>:</Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Typography className={classes.accordionDetail}>{data.check_in_address ? `${data.check_in_address}` : '-'}</Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography className={classes.accordionDetail}>Check Out Location</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className={classes.accordionDetail}>:</Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Typography className={classes.accordionDetail}>{data.check_out_address ? `${data.check_out_address}` : '-'}</Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                      <Grid item xs={3}>
                                        <Typography className={classes.accordionDetail}>To Do</Typography>
                                      </Grid>
                                      <Grid item>
                                        <Typography className={classes.accordionDetail}>:</Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <Typography className={classes.accordionDetail}>{data.todo ? `${data.todo}` : '-'}</Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems='center' justifyContent='center' style={{textAlign: '-webkit-center', marginTop: 18}}>
                                      <Grid item xs={6}>
                                        <Typography className={classes.accordionDetail}>Check In</Typography>
                                        <Link href={data.check_in_img} target="_blank" rel="noopener noreferrer">
                                          <CardMedia
                                            className={[classes.modalAvatar, classes.imgSize]}
                                            alt="check in picture"
                                            image={data.check_in_img}
                                            title='Check In'
                                            component='image'
                                          />
                                        </Link>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography className={classes.accordionDetail}>Check Out</Typography>
                                        <Link href={data.check_out_img} target="_blank" rel="noopener noreferrer">
                                          <CardMedia
                                            className={[classes.modalAvatar, classes.imgSize]}
                                            alt="check out picture"
                                            image={data.check_out_img}
                                            title='Check Out'
                                            component='image'
                                          />
                                        </Link>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </ThemeProvider>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </ThemeProvider>
              ) : (null) }
            {method === 'remote' || method ==='overtime' || method === 'office' ?
              (
                <Grid container spacing={3} align="center">
                  <Grid item xs={6}>
                    <Typography
                      className={classes.modalItemSpacing}
                    >
                      Check In
                    </Typography>
                    <Link href={checkInImg} target="_blank" rel="noopener noreferrer">
                      {!checkInImg ? (
                        <Avatar alt='user avatar' className={[classes.modalAvatar, classes.imgSize]} />
                      ) : (
                        <CardMedia
                          className={[classes.modalAvatar, classes.imgSize]}
                          alt="check in picture"
                          image={checkInImg}
                          title='Check In'
                          component='image'
                        />
                      )}
                    </Link>
                    <br />
                    <Grid container wrap="nowrap" spacing={2} align="center">
                      <Grid item xs={12}>
                        <Typography>Time &nbsp; : &nbsp; {checkIn ? checkIn : '-'} </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      className={classes.modalItemSpacing}
                    >
                      Check Out
                    </Typography>
                    <Link href={checkOutImg} target="_blank" rel="noopener noreferrer">
                    {!checkOutImg ? (
                      <Avatar alt='user avatar' className={[classes.modalAvatar, classes.imgSize]} />
                    ) : (
                      <CardMedia
                        className={[classes.modalAvatar, classes.imgSize]}
                        alt="check out picture"
                        image={checkOutImg}
                        title='Check Out'
                        component='image'
                      />
                    )}
                    </Link>
                    <br />
                    <Grid container spacing={2} >
                      <Grid item xs={12}>
                        <Typography>Time &nbsp; : &nbsp; {checkOut ? checkOut : '-'} </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null
            }
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }

  renderTableTitle = () => {
    const {classes} = this.props;
    return (
      <div className={classes.MUIDataTableToolbar}>
        <div className="d-flex">
          <Typography className={classes.tableTitle}>Attendance History</Typography>
          <ThemeProvider theme={this.tooltipTheme()}>
            <Tooltip title="Click Filter to see all data from attendance list">
              <IconButton className={classes.tableTooltip}>
                <HelpOutlineIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
          {/* <span className="">Remaining Quota: {remaining_quota}</span>   */}
        </div>
      </div>
    );
  };

  render() {
    const {classes, attendanceData, attendanceMeta, isLoading} = this.props;
    const {resetDatatable, resetPage} = this.state;
    const columns = [
      {name: 'no', label: 'No', display: true},
      {name: 'name', label: 'Name', display: true, customBodyRender: (value) => value ? value : '-'},
      {
        name: 'method',
        label: 'Type',
        display: true,
        customBodyRender: (value) => {
          if (value === 'business') value = 'Business Trip';
          if (value === 'office') value = 'Office Working';
          if (value === 'remote') value = 'Remote Working';
          return (<div style={{textTransform: 'capitalize'}}>{value}</div>);
        },
      },
      {name: 'date', label: 'Date', display: false, customBodyRender: (value) => value ? convDate(value, 'D MMMM YYYY') : '-'},
      {name: 'day', label: 'Day', display: true, customBodyRender: (value) => value ? value : '-'},
      {
        name: 'check_in',
        label: 'Check In',
        display: true,
        customBodyRender: (value, attendanceData) => {
          return value ?
            (
              <Tooltip title={attendanceData.rowData[8] ? 'late' : 'On Time'}>
                <Button
                  variant='outlined'
                  size="small"
                  startIcon={attendanceData.rowData[8] ? <WarningOutlined className={classes.checkInFalse} /> : <CheckCircleOutline className={classes.checkInTrue} />}
                  onClick={this.handleModal}
                >
                  {value}
                </Button>
              </Tooltip>
            ) : '-';
        },
      },
      {
        name: 'check_out',
        label: 'Check Out',
        display: true,
        customBodyRender: (value) => {
          return value ?
            (
              <Button
                variant='outlined'
                size="small"
                startIcon={<CheckCircleOutline className={classes.checkInTrue} />}
                onClick={this.handleModal}
              >
                {value}
              </Button>
            ) : '-';
        },
      },
      {
        name: 'working_time', label: 'Working Time', display: true,
      },
      {
        name: 'is_late', label: 'Late', display: false,
      },
    ];

    const {query, startDate, endDate, method} = this.state;

    return (
      <div className={classes.root}>
        {
          featureFlag.attendance_history.filter_same_export_data ? 
          <RenderGlobalFilter 
          filterCheckbox={this.state.filterCheckbox} 
          query={query} 
          startDate={startDate} 
          endDate={endDate} 
          method={method}
          handleSearch={this.handleSearch} 
          handleFilterChange={this.handleFilterChange}
          handleExport={this.handleExport} 
          handleButtonFilter={this.handleButtonFilter} 
          handleReset={this.handleReset}
          handleFilterCheckbox={this.handleFilterCheckbox} 
          handleFilterDateChange={this.handleFilterDateChange} 
          handleModalImport={this.handleModalImport}
          renderMethodOptions={this.renderMethodOptions}
          disabledExport={attendanceData?.length < 1 ? true : false}
          redirectToExportActivity={() => this.props.history.push('/export-activity')}
        />
          :
          this.renderFilter()
        }
        <Datatable
          creatable={false}
          title={this.renderTableTitle()}
          loading={isLoading}
          dataSource={attendanceData}
          total={attendanceMeta.total}
          page={attendanceMeta.page}
          columns={columns}
          handleReload={(params) => this.handleReload(params)}
          handleDetail={(params) => this.toggleModal('detail', params)}
          toggleResetAll={resetDatatable}
          toggleResetPage={resetPage}
          manualNumbering={true}
        />
        {this.renderModalDetail()}
        {this.renderSnackbar()}
        {this.renderModalImport()}
      </div>
    );
  }
}

const mapStateToProps = ({attendanceHistory, userManagement, auth, generalSetting}) => ({
  isLoading: attendanceHistory.isLoading,
  isSuccess: attendanceHistory.isSuccess,
  isError: attendanceHistory.isError,
  message: attendanceHistory.message,
  attendanceData: attendanceHistory.attendanceData,
  attendanceMeta: attendanceHistory.attendanceMeta,
  detailLoading: attendanceHistory.detailLoading,
  detailData: attendanceHistory.detailData,
  exportLoading: attendanceHistory.exportLoading,
  importLoading: attendanceHistory.importLoading,
  exportData: attendanceHistory.exportData,
  trigger: attendanceHistory.trigger,
  attendanceTypes: userManagement.attendanceTypes,
  activeBusinessCheckout: auth.data.menus?.checkout_business_trip,
  triggerSetting: generalSetting.trigger,
  generalSetting: generalSetting.data,
  orgStatusData: organization.orgStatusData,
});

const mapDispatchToProps = (dispatch) => ({
  getAttendanceHistory: (params) => dispatch(getAttendanceHistory(params)),
  getAttendanceDetail: (params) => dispatch(getAttendanceDetail(params)),
  exportExcel: (params) => dispatch(exportExcel(params)),
  importExcel: (data) => dispatch(importExcel(data)),
  downloadExcelTemplate: (params) => dispatch(downloadExcelTemplate(params)),
  checkStatus: (params) => dispatch(checkStatus(params)),
  updateExportNotification: (params) => dispatch(updateExportNotification(params)),
  updateImportNotification: (params) => dispatch(updateImportNotification(params)),
  getGeneralSetting: () => dispatch(getGeneralSetting()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AttendanceHistory));
