/* eslint-disable react/no-deprecated */
/* eslint-disable max-len */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Card, CardHeader, CardMedia, Grid} from '@material-ui/core';
import {Row, Col, Input, Button, Spinner} from 'reactstrap';
import Swal from 'sweetalert2';
import styles from './styles';
import './style.css';
import {
  getPaidLeaveByToken,
  confirmApproval,
  resetLoader,
} from './../../redux/approval/approval.actions';
import moment from 'moment';
import {toast} from '../../helpers';
import bgApprove from '../../assets/img/bg.png';
import pagiiLogo from '../../assets/img/pagii-simple-attendance-solution-logo.png';
import featureFlag from "../../config/featureFlag";

const regexReason = new RegExp(/^$|.*\S+.*/);
export class Approval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathVariable: this.props.history?.location?.pathname?.replace('/approval/leave/', ''),
      reason: {
        value: '',
        isError: false,
        isSuccess: false,
        message: '',
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const {pathVariable} = this.state;
    const status = pathVariable.split('/')[0]?? '';

    if (nextProps.isError) {
      toast('error', nextProps.message);
      setTimeout(() => {
        this.props.resetLoader();
      }, 500);
    }

    if (nextProps.isDone) {
      this.props.history.replace('/419');
      setTimeout(() => {
        this.props.resetLoader();
      }, 500);
    }

    if (nextProps.isSuccessSubmitApproval) {
      toast('success', `Leave has been ${status === 'approve' ? 'approved' : 'rejected'}`);
      setTimeout(() => {
        this.props.history.replace('/leave-management');
        this.props.resetLoader();
      }, 500);
    }
  }

  componentDidMount() {
    const {pathVariable} = this.state;
    const status = pathVariable.split('/')[0]?? '';
    const token = pathVariable.split('/')[1]?? '';
    this.props.getPaidLeaveByToken(token);

    if (status === 'approve') {
      this.setState({reason: {
        value: '',
        isError: false,
        isSuccess: false,
      }});
    }
  }

  componentWillUnmount() {
    this.props.resetLoader();
  }

  handleChangeValue = (type, value) => {
    if (type === 'reason') {
      const reason = {...this.state.reason};

      reason.value = value;

      if (regexReason.test(value) && value) {
        reason.isError = false;
        reason.isSuccess = true;
        reason.message = '';
      } else {
        reason.isError = true;
        reason.isSuccess = false;
        reason.message = 'Notes is required.';
      }
      this.setState({reason});
    } else {
      this.setState({[type]: value});
    }
  }

  onConfirm = () => {
    const {pathVariable, reason} = this.state;
    const status = pathVariable.split('/')[0]?? '';
    const token = pathVariable.split('/')[1]?? '';

    const params = {
      token,
      status: status === 'approve' ? 'approved' : 'rejected',
      rejected_reason: reason.value,
    };

    if (status === 'approve') {
      if (reason.isSuccess) {
        this.props.confirmApproval(params);
      } else {
        this.setState((prevState) => ({
          reason: {
            ...prevState.reason,
            isError: true,
            isSuccess: false,
            message: 'Notes is required.',
          },
        }));
      }
    } else if (status === 'reject') {
      if (reason.isSuccess) {
        this.props.confirmApproval(params);
      } else {
        this.setState((prevState) => ({
          reason: {
            ...prevState.reason,
            isError: true,
            isSuccess: false,
            message: 'Notes is required.',
          },
        }));
      }
    }
  }

  render() {
    const {classes, data, isLoadingSubmitApproval, isLoading, isDone} = this.props;
    const {pathVariable, reason} = this.state;

    const status = pathVariable.split('/')[0]?? '';
    const token = pathVariable.split('/')[1]?? '';
    const new_start_date = data?.start_date?.split('-');
    const new_end_date = data?.end_date?.split('-');
    const a = new_start_date !== undefined ? moment([new_end_date[0], new_end_date[1] - 1, new_end_date[2]]) : '';
    const b = new_end_date !== undefined ? moment([new_start_date[0], new_start_date[1] - 1, new_start_date[2]]) : '';
    const total = featureFlag.leaveConfirmationTotalDays.enable_adjust ? data.total_days : new_start_date !== undefined && new_end_date !== undefined ? a.diff(b, 'days') : '';

    if (isLoading) {
      return (
        <div style={{
          width: '100%',
          height: '100vh',
          padding: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${bgApprove})`,
          backgroundSize: 'cover',
        }}>
          <Spinner/>
        </div>
      );
    } else {
      return (
        <div className="content" style={{
          width: '100%',
          height: '100vh',
          padding: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${bgApprove})`,
          backgroundSize: 'cover',
        }}>
          <Card className="card-view" style={{
            width: '70%',
            height: 'auto',
            padding: '2em 5em',
            backgroundColor: 'rgb(255 255 255 / 75%)',
          }}>
            <Row style={featureFlag.leaveConfirmationTotalDays.enable_adjust ? {margin: 0} : {}}>
              <Col style={customStyle.d_flex} className="card-title-app">
                <div className="mr-auto logo">
                  <img src={pagiiLogo} style={featureFlag.leaveConfirmationTotalDays.enable_adjust ? {width: '7em', position:'absolute',left: 0,top: 0}: {width: '7em'}} alt="pagiiLogo"/>
                </div>
                <h2 className="mr-auto title">Leave Confirmation</h2>
              </Col>
            </Row>
            <hr />
            <div className="card-content" style={{padding: '1em 7em'}}>
              <div>
                <div className="text-center">
                  <h5><span><b>{data?.name?? '-'}</b></span>, <span>needs your confirmation for a leave request</span></h5>
                  {featureFlag.leaveConfirmationTotalDays.enable_adjust ?
                  <h5>Request Date: {data?.start_date? moment(data.start_date).format('DD MMMM YYYY') : '-'} - {data?.end_date? moment(data.end_date).format('DD MMMM YYYY') : '-'} ({total} Day{total <= 1 ? '' : 's'})</h5>
                    :
                  <h5>Request Date: {data?.start_date? moment(data.start_date).format('DD MMMM YYYY') : '-'} - {data?.end_date? moment(data.end_date).format('DD MMMM YYYY') : '-'} ({total + 1} Day{total + 1 <= 1 ? '' : 's'})</h5>
                  }
                </div>
              </div>
              {status === 'reject' ?
                <>
                  <h5 className="mt-5 text-center font-weight-bold">Detail</h5>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{
                      borderBottom: '3px solid rgb(51, 51, 51)',
                      padding: '0.5em 1em',
                      textalign: 'center',
                      // borderRadius: '10px'
                    }}>
                      <strong><i>{data?.details?? '-'}</i></strong>
                    </div>
                  </div>

                  <h5 className="mt-5 text-center font-weight-bold">Notes</h5>
                  <div>
                    <div style={{
                      border: '3px solid rgb(51, 51, 51)',
                      padding: '0.5em 1em',
                      textalign: 'center',
                      borderRadius: '10px',
                      marginTop: '1.5em',
                    }}>
                      <Input
                        style={{backgroundColor: 'transparent', border: 0, boxShadow: 'none'}}
                        className='form-control'
                        placeholder="Type your notes here.."
                        name="reason"
                        value={reason.value}
                        onChange={(e) => this.handleChangeValue('reason', e.target.value)}
                      />
                    </div>
                    <span className="text-danger" style={{display: `${reason.isError && !reason.isSuccess ? 'block' : 'none'}`, marginTop: 3}}>{reason.message}</span>
                  </div>
                </> :
                <>
                  <h5 className="mt-5 text-center font-weight-bold">Detail</h5>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div style={{
                      borderBottom: '3px solid rgb(51, 51, 51)',
                      padding: '1.5em 1em',
                      textalign: 'center',
                      // borderRadius: '10px',
                    }}>
                      <strong><i>{data?.details?? '-'}</i></strong>
                    </div>
                  </div>

                  <h5 className="mt-5 text-center font-weight-bold">Notes</h5>
                  <div>
                    <div style={{
                      border: '3px solid rgb(51, 51, 51)',
                      padding: '0.5em 1em',
                      textalign: 'center',
                      borderRadius: '10px',
                      marginTop: '1.5em',
                    }}>
                      <Input
                        style={{backgroundColor: 'transparent', border: 0, boxShadow: 'none'}}
                        className='form-control'
                        placeholder="Type your notes here.."
                        name="reason"
                        value={reason.value}
                        onChange={(e) => this.handleChangeValue('reason', e.target.value)}
                      />
                    </div>
                    <span className="text-danger" style={{display: `${reason.isError && !reason.isSuccess ? 'block' : 'none'}`, marginTop: 3}}>{reason.message}</span>
                  </div>
                </>
                }
            </div>
            <br/>

            {status === 'reject' ?
              <Row>
              <Col style={customStyle.d_flex} className="mt-1">
                <Button style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#fe0115',
                  borderColor: '#3f51b5',
                  padding: '.5em 2em',
                  fontSize: '24px',
                }} type="button" disabled={isLoadingSubmitApproval || isLoading} onClick={() => this.onConfirm()} className="btn btn-success btn-lg mt-4">Reject {isLoadingSubmitApproval ? <Spinner size="sm" /> : ''}</Button>
              </Col>
            </Row> :
              <Row>
                <Col style={customStyle.d_flex} className="mt-1">
                  <Button style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#35c0f3',
                    borderColor: '#35c0f3',
                    padding: '.5em 2em',
                    fontSize: '24px',
                  }} type="button" disabled={isLoadingSubmitApproval || isLoading} onClick={() => this.onConfirm()} className="btn btn-success btn-lg mt-4">Confirm {isLoadingSubmitApproval ? <Spinner size="sm" /> : ''}</Button>
                </Col>
              </Row>
            }

          </Card>
        </div>
      );
    }
  }
}

const customStyle = {
  d_flex: featureFlag.leaveConfirmationTotalDays.enable_adjust ? {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  } : {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

const mapStateToProps = (state) => ({
  isLoading: state.approval.isLoading,
  isSuccess: state.approval.isSuccess,
  isError: state.approval.isError,
  message: state.approval.message,
  data: state.approval.data,
  isDone: state.approval.isDone,
  isLoadingSubmitApproval: state.approval.isLoadingSubmitApproval,
  isSuccessSubmitApproval: state.approval.isSuccessSubmitApproval,
});

const mapDispatchToProps = (dispatch) => ({
  getPaidLeaveByToken: (params) => dispatch(getPaidLeaveByToken(params)),
  confirmApproval: (params) => dispatch(confirmApproval(params)),
  resetLoader: () => dispatch(resetLoader()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Approval));
