/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  withStyles,
  Grid,
  Link,
  Checkbox,
  Container,
  FormControlLabel,
  CssBaseline,
  IconButton,
  Box,
  Paper,
  Typography,
  Tooltip,
} from '@material-ui/core';
import {
  Visibility,
  VisibilityOff,
  Input,
  ChevronLeft,
  InfoOutlined,
} from '@material-ui/icons';
import {createTheme, MuiThemeProvider} from '@material-ui/core/styles';
import Recaptcha from 'react-recaptcha';
import LoginIcons from './../../assets/img/pagii-simple-attendance-solution-logo.png';
import styles from './styles';
import {MButton, MCheckboxGroup, MCheckboxItem, MInput, MInputMask, MSelect} from '../../components/form';
import {toast, isObject} from '../../helpers';
import loginImage from '../../assets/img/login/loginImg.png'; // Import using relative path
import {Button} from '@mui/material';

import {registerSSO, clearState} from '../../redux/register/register.actions';
import {getOrganizationTypeWithoutAuth} from '../../redux/organization/organization.actions';
import {REGISTER_STATE as STATE} from '../../redux/register/register.types';
import customTheme from '../../components/theme/theme';
import featureFlag from '../../config/featureFlag';

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
const REGISTER_LINK = '/register-sso';


class RegisterSSO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: null,
      email: null,
      phone: null,
      purpose: null,
      organization_name: null,
      organization_addres: null,
      pic_name: null,
      pic_email: null,
      pic_phone: null,
      organization_type_id: null,
      number_of_employees: null,
      feature: {remote: false, office: false, business: false},
      referral_id: null,
      disabled_reff_id: false,
      showOthers: false,
      errorMessage: null,
      isVerified: true,
      tnc: false,
      npwp_id: "",
      npwp_holder_name: "",
    };
    this.initialState = this.state;
  }

  multilineText = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
        input: {
          padding: 'none',
        },
      },
    },
  })

  selectFieldTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
        input: {
          padding: '13.5px 14px !important',
        },
      },
      MuiInputLabel: {
        shrink: {
          transform: 'translate(14px, -6px) scale(0.75)',
          opacity: 0,
        },
        outlined: {
          transform: 'translate(14px, 15px) scale(1)',
          color: 'darkgray',
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: '0px !important',
        },
      },
    },
  })
  formTheme = () => createTheme({
    overrides: {
      MuiRadio: {
        '$&checked': {
          color: '#00AFF0',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
        input: {
          padding: '13.5px 14px !important',
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: 8,
          marginBottom: 16,
        },
      },
      MuiFormHelperText: {
        contained: {
          marginLeft: '0px !important',
        },
      },
    },
    palette: {
      default: {
        main: '#F00B38',
      },
      primary: {
        main: '#00AFF0',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#F00B38',
      },
      regular: {
        main: '#0198D0',
      },
    },
  });


  getMuiTheme = () => createTheme({
    palette: {
      primary: {
        main: '#00AFF0',
      },
    },
    overrides: {
      MuiContainer: {
        maxWidthXs: {
          maxWidth: '75%',
        },
        maxWidthLg: {
          maxWidth: '1080px !important',
          maxheight: '680px !important',
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: '8px !important',
          marginBottom: '14px !important',
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
        input: {
          padding: '13.5px 14px !important',
        },
      },
    },
  })

  UNSAFE_componentWillMount = () => {
    this.props.getOrganizationTypeWithoutAuth();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const {trigger, isSuccess, isError, message} = nextProps;
    const url_string = window.location.href;
    const url = new URL(url_string);
    const appId = url.searchParams.get('appId');
    if (isSuccess) {
      if (trigger === STATE.REGISTER_SSO_SUCCESS) {
        toast('success', message);
        this.props.history.push(`/login?appId=${appId}`);
      }
    }

    if (isError && message && isObject(message)) this.setState({errorMessage: message});
    if (isError && message && !isObject(message)) toast('error', message);
  }

  componentWillUnmount = () => {
    this.props.getOrganizationTypeWithoutAuth();
  }

  componentWillUnmount = () => {
    this.props.clearState();
  }

  handleResetForm = () => {
    this.setState(this.initialState);
  }

  verifyCallback = (response) => {
    if (response) {
      this.setState({
        isVerified: true,
      });
    }
  }
  onloadCallback = () => {
    this.setState({
      isVerified: false,
    });
  }
  expiredCallback = (response) => {
    if (!response) {
      this.setState({
        isVerified: false,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      name, email, phone, organization_name,
      pic_name, pic_email, pic_phone,
      organization_addres, organization_type_id, number_of_employees,
      purpose, referral_id, isVerified, npwp_id, npwp_holder_name,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const appId = url.searchParams.get('appId');

    if (isVerified) {
      const params = {
        ...(name && {name}),
        ...(email && {email}),
        ...(phone && {phone}),
        ...(purpose && {purpose}),
        ...(organization_name && {organization_name}),
        // ...(pic_name && {pic_name}),
        // ...(pic_email && {pic_email}),
        // ...(pic_phone && {pic_phone}),
        ...(organization_type_id && {organization_type_id}),
        ...(organization_addres && {organization_addres}),
        ...(number_of_employees && {number_of_employees}),
        ...(referral_id && {referral_id}),
        ...(appId && {appId}),
        ...(npwp_id && {npwp_id}),
        ...(npwp_holder_name && {npwp_holder_name}),
      };
      this.props.registerSSO(params);
    } else {
      toast('error', 'Please verify captcha');
    }
  }

  handleChange = (event) => {
    const {name, value} = event.target;
    const {errorMessage} = this.state;
    if(name === 'npwp_holder_name') {
      if(value.length < 4) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_holder_name: 'NPWP holder must not be less than 4 character'},
          },
          [name]: value,
        });
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_holder_name: null},
          },
          [name]: value,
        });
      }
    } else if(name === 'npwp_id') {
      let validator = /[a-zA-Z]/g.test(value);

      if(validator) {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_id: 'NPWP ID cannot input letter character'},
          },
          [name]: value,
        });
      } else {
        this.setState({
          errorMessage: {
            ...errorMessage,
            ...{npwp_id: null},
          },
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
        errorMessage: {...this.state.errorMessage, [name]: null},
      });
    }
  };

  handleCheckboxChange = (event) => {
    const {errorMessage} = this.state;
    const {name, checked} = event.target;
    this.setState({
      errorMessage: {...errorMessage, [name]: null},
      [name]: checked,
    });
  }

  handleTypeChange = (event) => {
    const {name, value} = event.target;
    const showOthers = (value === 0) ? true : false;
    this.setState({
      showOthers,
      [name]: value,
      errorMessage: {...this.state.errorMessage, [name]: null},
    });
  }

  handleTogglePassword = () => {
    this.setState({showPassword: !this.state.showPassword});
  }

  renderRegister = () => {
    const {classes, isLoading, orgTypeData} = this.props;
    const {
      name,
      email,
      phone,
      organization_name,
      organization_addres,
      organization_type_id,
      number_of_employees,
      purpose,
      pic_name,
      pic_email,
      pic_phone,
      errorMessage,
      tnc,
      npwp_id,
      npwp_holder_name,
    } = this.state;

    const url_string = window.location.href;
    const url = new URL(url_string);
    const appId = url.searchParams.get('appId');


    const companyWorkers = [
      {id: '< 50 peoples', name: '< 50 peoples'},
      {id: '50 - 100 peoples', name: '50 - 100 peoples'},
      {id: '100 - 250 peoples', name: '100 - 250 peoples'},
      {id: '250 - 500 peoples', name: '250 - 500 peoples'},
      {id: '> 500 peoples', name: '> 500 peoples'},
    ];

    const companyType = [];
    orgTypeData && orgTypeData.map((item) => {
      companyType.push({id: item.id, name: item.name});
    });
    return (
      <MuiThemeProvider theme={this.getMuiTheme()}>
        <div>

          <Container component="main" >
            <CssBaseline />
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
              <Grid container justify='center'>
                <Grid item xs={12} sm={8}>
                  <Paper className={classes.papersRight} >
                    <Box p={2} className={classes.paper}>
                      <MuiThemeProvider theme={this.formTheme()}>
                        {/* <img src={LoginIcons} style={{width: '80px', height: '80px', borderRadius: '50%'}} alt="Logo" /> */}
                        <img src={LoginIcons} style={{width: 'auto', height: '40px'}} alt="Logo" />
                        <Typography className={classes.loginTitle}>REGISTRATION</Typography>
                        <form className={classes.form} noValidate method="POST" onSubmit={this.handleSubmit}>
                        <Typography className={classes.formLabel}>PIC Name</Typography>
                          <MInput
                            classNameFC={classes.forminputreg}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            placeholder='Full Name'
                            name="name"
                            onChange={this.handleChange}
                            value={name}
                            error={errorMessage && errorMessage.name ? errorMessage.name : undefined}
                          />
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                            <Typography className={classes.formLabel}>
                                PIC Email
                                <Tooltip placement='right-end' title="This email will be use as login credential for your company">
                                  <InfoOutlined 
                                    style={{
                                      fontSize: 16,
                                      cursor: 'pointer',
                                      marginLeft: 4,
                                    }}
                                  />
                                </Tooltip>
                              </Typography>
                              <MInput
                                classNameFC={classes.forminputreg}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                placeholder='name@email.com'
                                name="email"
                                onChange={this.handleChange}
                                value={email}
                                error={errorMessage && errorMessage.email ? errorMessage.email : undefined}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography className={classes.formLabel}>PIC Phone Number</Typography>
                              <MInputMask
                                classNameFC={classes.forminputreg}
                                style={{marginTop: '8px'}}
                                name="phone"
                                placeholder="Phone"
                                type="number"
                                defaultValue={phone}
                                onBlur={this.handleChange}
                                error={
                                  errorMessage && errorMessage.phone ?
                                    errorMessage.phone.split(':')[0] :
                                    undefined
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography className={classes.formLabel}>Company Name</Typography>
                              <MInput
                                classNameFC={classes.forminputreg}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="organization_name"
                                placeholder='Company Name'
                                name="organization_name"
                                onChange={this.handleChange}
                                value={organization_name}
                                error={errorMessage && errorMessage.organization_name ? errorMessage.organization_name : undefined}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography className={classes.formLabel}>Company Address</Typography>
                              <MInput
                                classNameFC={classes.forminputreg}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="organization_addres"
                                placeholder='Company Address'
                                name="organization_addres"
                                onChange={this.handleChange}
                                value={organization_addres}
                                error={errorMessage && errorMessage.organization_addres ? errorMessage.organization_addres : undefined}
                              />
                            </Grid>
                          </Grid>
                          {featureFlag.register.enable_npwp ? (
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <Typography className={classes.formLabel}>NPWP Company Name</Typography>
                                  <MInput
                                    classNameFC={classes.forminputreg}
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="npwp_holder_name"
                                    placeholder='NPWP Company Name'
                                    name="npwp_holder_name"
                                    onChange={this.handleChange}
                                    value={npwp_holder_name}
                                    error={errorMessage && errorMessage.npwp_holder_name ? errorMessage.npwp_holder_name : undefined}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <Typography className={classes.formLabel}>NPWP ID</Typography>
                                  <MInput
                                    classNameFC={classes.forminputreg}
                                    variant="outlined"
                                    margin="normal"
                                    requiredx
                                    fullWidth
                                    id="npwp_id"
                                    placeholder='NPWP ID'
                                    name="npwp_id"
                                    onChange={this.handleChange}
                                    value={npwp_id}
                                    error={errorMessage && errorMessage.npwp_id ? errorMessage.npwp_id : undefined}
                                  />
                                </Grid>
                              </Grid>
                            </>
                          ) : null}
                          {/* <Typography className={classes.formLabel}>PIC Name</Typography>
                          <MInput
                            classNameFC={classes.forminputreg}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="pic_name"
                            placeholder='pic name'
                            name="pic_name"
                            onChange={this.handleChange}
                            value={pic_name}
                            error={errorMessage && errorMessage.pic_name ? errorMessage.pic_name : undefined}
                          />
                          <Typography className={classes.formLabel}>PIC Email</Typography>
                          <MInput
                            classNameFC={classes.forminputreg}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="pic_email"
                            placeholder='pic email'
                            name="pic_email"
                            onChange={this.handleChange}
                            value={pic_email}
                            error={errorMessage && errorMessage.pic_email ? errorMessage.pic_email : undefined}
                          />
                          <Typography className={classes.formLabel}>PIC Phone Number</Typography>
                          <MInput
                            classNameFC={classes.forminputreg}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="pic_phone"
                            placeholder='pic phone number'
                            name="pic_phone"
                            onChange={this.handleChange}
                            value={pic_phone}
                            error={errorMessage && errorMessage.pic_phone ? errorMessage.pic_phone : undefined}
                          /> */}
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <Typography className={classes.formLabelSelect}>Company Sector</Typography>
                              <MuiThemeProvider theme={this.selectFieldTheme()}>
                                <MSelect
                                  name="organization_type_id"
                                  label="company sector"
                                  placeholder="Company Sector"
                                  keyPair={['id', 'name']}
                                  id="company_sector"
                                  options={companyType}
                                  onChange={this.handleTypeChange}
                                  error={errorMessage && errorMessage.organization_type_id ? errorMessage.organization_type_id : undefined}
                                  variant='outlined'
                                  notched={false}
                                  shrink={false}
                                />
                              </MuiThemeProvider>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography className={classes.formLabelSelect}>Number of Employees</Typography>
                              <MuiThemeProvider theme={this.selectFieldTheme()}>
                                <MSelect
                                  name="number_of_employees"
                                  label="number of employees"
                                  placeholder="Your company approximate total employees"
                                  keyPair={['id', 'name']}
                                  id="number_of_employees"
                                  options={companyWorkers}
                                  onChange={this.handleTypeChange}
                                  error={errorMessage && errorMessage.number_of_employees ? errorMessage.number_of_employees : undefined}
                                  variant='outlined'
                                  notched={false}
                                  shrink={false}
                                />
                              </MuiThemeProvider>
                            </Grid>
                          </Grid>
                          {/* <Typography className={classes.formLabel}>Objective (optional)</Typography>
                          <MuiThemeProvider theme={this.multilineText()}>
                            <Minput
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="purpose"
                              placeholder='explain what your objective using this application'
                              name="purpose"
                              onChange={this.handleChange}
                              value={purpose}
                              multiline
                              rows={3}
                              error={errorMessage && errorMessage.purpose ? errorMessage.purpose : undefined}
                            />
                          </MuiThemeProvider> */}
                          {/* <Grid container style={{justifyContent: 'space-between'}}>
                            <Grid item>
                              <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                              />
                            </Grid>
                            <Grid item>
                              <Link href='/forgot-password' underline='none'>
                                <Typography className={classes.forgotPasswordText}>
                                  Forgot Password ?
                                </Typography>
                              </Link>
                            </Grid>
                          </Grid> */}
                          <Box align="center" style={{marginTop: 8}}>
                            <Recaptcha
                              fullWidth
                              sitekey={RECAPTCHA_SITE_KEY}
                              render="explicit"
                              elementID="a1"
                              onloadCallback={this.onloadCallback}
                              expiredCallback={this.expiredCallback}
                              verifyCallback={this.verifyCallback}
                              // onloadCallback={true}
                              // expiredCallback={true}
                              // verifyCallback={true}
                            />
                          </Box>
                          <MButton
                            fullWidth
                            className={classes.submit}
                            id="submit_register"
                            label="Register"
                            color="primary"
                            type='submit'
                            loading={isLoading}
                            // disabled={tnc ? false : true}

                          />
                          <Grid item xs={12}>
                            <div style={{fontSize: 12, marginTop: 18, textAlign: 'center'}}>
                              <span>By registering, you are agree to our </span>
                              <Link href="https://pagii.co/kebijakan-privasi" target="_blank" rel="noopener noreferrer">
                                <span>Privacy Policy </span>
                              </Link>
                              <span>also </span>
                              <Link href="https://pagii.co/ketentuan-pengguna/" target="_blank" rel="noopener noreferrer">
                                <span>Terms and Conditions</span>
                              </Link>
                            </div>
                          </Grid>
                          <Link href={`/login?appId=${appId}`} underline='none' id='back_to_login'>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                              <ChevronLeft style={{
                                marginTop: 7,
                                fontSize: 24,
                                color: '#00AFF0',
                              }} />
                              <Typography className={classes.forgotPasswordText}>
                                Back to Login
                              </Typography>
                            </div>
                          </Link>
                        </form>
                        <Typography className={classes.copyright} style={{textAlign: 'center'}}>Copyright 2020 ©  PT Smooets Teknologi Outsourcing</Typography>
                      </MuiThemeProvider>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
      </MuiThemeProvider>
    );
  }

  render() {
    return (
      <div>
        {this.renderRegister()}
      </div>
    );
  }
}

const mapStateToProps = ({auth, organization, register}) => ({
  token: auth.data.authorization,
  isLoading: register.isLoading,
  isError: register.isError,
  isSuccess: register.isSuccess,
  message: register.message,
  trigger: register.trigger,
  orgTypeData: organization.orgTypeData,
});

const mapDispatchToProps = (dispatch) => ({
  getOrganizationTypeWithoutAuth: (params) => dispatch(getOrganizationTypeWithoutAuth(params)),
  registerSSO: (params) => dispatch(registerSSO(params)),
  clearState: () => dispatch(clearState()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(RegisterSSO));
